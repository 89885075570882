import imgT1 from "../assets/image/jpg/agency/masonry/portfolio-mas-8.jpg";
import imgT2 from "../assets/image/jpg/agency/masonry/portfolio-mas-7.jpg";
import imgT3 from "../assets/image/jpg/agency/masonry/portfolio-mas-6.jpg";
import imgT4 from "../assets/image/jpg/agency/masonry/portfolio-mas-5.jpg";
import imgT5 from "../assets/image/jpg/agency/masonry/portfolio-mas-4.jpg";
import imgT6 from "../assets/image/jpg/agency/masonry/portfolio-mas-3.jpg";
import imgT7 from "../assets/image/jpg/agency/masonry/portfolio-mas-16.jpg";
import imgT8 from "../assets/image/jpg/agency/masonry/portfolio-mas-15.jpg";
import imgT9 from "../assets/image/jpg/agency/masonry/portfolio-mas-2.jpg";
import imgT10 from "../assets/image/jpg/agency/masonry/portfolio-mas-1.jpg";
import imgT11 from "../assets/image/jpg/agency/masonry/portfolio-mas-12.jpg";
import imgT12 from "../assets/image/jpg/agency/masonry/portfolio-mas-11.jpg";
import imgT13 from "../assets/image/jpg/agency/masonry/portfolio-mas-14.jpg";
import imgT14 from "../assets/image/jpg/agency/masonry/portfolio-mas-13.jpg";

export const workProjects = [
  {
    brand: `IDMS`,
    slug: `IDMS`,
    title: `Adaptable but Identifiable Product for Public Service Federal Finance.`,
    categories: ["SPFF,angular,spring,java,springBoot"],
    thumbnail: imgT1,
  link : "/portfolio-details"
},
  {
    brand: `Message-store`,
    slug: `Message-store`,
    title: `Adaptable but Identifiable Product for Public Service Federal Finance.`,
    categories: [`java`, `spring`],
    thumbnail: imgT2,
  link : "/portfolio-details"
},
  {
    brand: `RESU`,
    slug: `RESU`,
    title: `Adaptable but Identifiable Product for Public Service Federal Finance.`,
    categories: ["SPFF,angular,spring,java,springBoot"],
    thumbnail: imgT3,
  link : "/portfolio-details"
},
  {
    brand: 'Cooperative',
    slug: `Cooperative`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`angular,java`],
    thumbnail: imgT4,
  link : "/portfolio-details"
},
  {
    brand: `Rent calculator`,
    slug: `Rent calculator`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`angular`],
    thumbnail: imgT5,
  link : "/portfolio-details"
},
  {
    brand: `Soctar`,
    slug: `Soctar`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`angular`, `springboot`,"spring"],
    thumbnail: imgT7,
  link : "/portfolio-details"
},
  {
    brand: `Service now distribution`,
    slug: `Service now distribution`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`java`, `spring`, "springboot"],
    thumbnail: imgT8,
  link : "/portfolio-details"
},
  {
    brand: `Index publication`,
    slug: `Index publication`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`java`],
    thumbnail: imgT9,
  link : "/portfolio-details"
},
  {
    brand: `Index Search”`,
    slug: `Index Search”`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`angular`],
    thumbnail: imgT10,
  link : "/portfolio-details"
},
  {
    brand: `Mandates”`,
    slug: `Mandates”`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`java`, `java ee`],
    thumbnail: imgT11,
  link : "/portfolio-details"
},
  {
    brand: `Petrolprices”`,
    slug: `Petrolprices”`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`java`,`java ee`],
    thumbnail: imgT12,
  link : "/portfolio-details"
},
  {
    brand: `Meldpunt Consult`,
    slug: `Meldpunt Consult`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`java`,`java ee`],
    thumbnail: imgT13,
  link : "/portfolio-details"
},
  {
    brand: `TAEG calculus`,
    slug: `TAEG calculus`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`java`,`java ee`],
    thumbnail: imgT14,
  link : "/portfolio-details"
},
];
